import { mscOperationTypes } from '../constants/msc-operation-types';
import { createFetcher } from './createFetcher';

export const createFieldsCroppingFetcher = createFetcher<
    CroppingFieldData[],
    {
        field_ids?: number[];
    }
>(
    () => `${process.env.REACT_APP_API_BASE_URL}/v3/cropping-view-msc`,
    (data) => {
        // console.log('MSC DATA', data);
        return data;
    },
);

export interface CroppingFieldData {
    field_id: number;
    field_name: string;
    field_area: number;
    cropping_fields: null | CroppingField[];
}

export interface Crop {
    crop_name: string;
    end_date: string | null;
    farm_season_id: number;
    field_crop_id: number;
    field_id: number;
    is_perennial: boolean | null;
    is_permanent: boolean;
    start_date: string | null;
}

export interface Operation {
    farm_season_id: number;
    field_crop_id: number;
    field_id: number;
    operation_date: string;
    operation_id: number;
    operation_name: keyof typeof mscOperationTypes;
}

export interface CroppingField {
    id: number;
    farm_season_id: number;
    year: number;
    overlap: number;
    name: string;
    area: number;
    crops: Crop[];
    operations: Operation[];
}
