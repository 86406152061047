/* eslint-disable @typescript-eslint/no-explicit-any */
import { createFetcher } from './createFetcher';

export const createDndcCroppingFetcher = createFetcher<
    CroppingViewData[],
    {
        field_ids?: number[];
    }
>(
    () => `${process.env.REACT_APP_API_BASE_URL}/v3/cropping-view-dndc`,
    (data) => {
        // console.log('DNDC DATA', data);
        return data;
    },
);

export interface CroppingViewData {
    field_id: number;
    status: 'SUCCEEDED' | 'FAILED' | 'SKIPPED';
    updated_at: string;
    phases: DndcPhase[];
}

export interface DndcPhase {
    end_date: string;
    start_date: string;
    fuel_consumption: null;
    management_events: Managementevents;
}

interface Managementevents {
    fire: any[];
    till: (Till | Till)[];
    flood: any[];
    cropping: Cropping[];
    fertilizer: (Fertilizer | Fertilizer)[];
    irrigation: any[];
    organic_amendment: any[];
}

interface Fertilizer {
    date: string;
    name: string;
    amount: number;
    custom?: Custom;
    area_unit: string;
    rate_unit: string;
    application_method: string;
}

interface Custom {
    urea_percent: number;
    liquid_density: null;
    ammonia_percent: number;
    nitrate_percent: number;
    sulfate_percent: number;
    ammonium_percent: number;
    phosphate_percent: number;
    mass_liquid_density_unit: null;
}

export interface Cropping {
    name: string;
    end_date: string;
    start_date: string;
    reductions?: Reduction[];
}

interface Reduction {
    date: string;
    type: string;
}

interface Till {
    date: string;
    depth: number;
    soil_inverted: boolean;
}
