import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities from '@shared/entities';
import { useForm } from '@shared/hooks';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { CompanyT } from '@modules/billing/api/userCompany.types';
import {
    usePostUserCompanyMutation,
    usePutUserCompanyMutation,
    usePutIbanMutation,
} from '@modules/billing/api/userCompanyApi';
import checkVATNumber from '@modules/billing/utils/checkVatNumber';
import { useSnackbar } from 'notistack';
import { countrySlugs } from '@shared/constants/countrySlugs';
import { useState, useEffect, useCallback } from 'react';
import { isValidIBAN, electronicFormatIBAN } from 'ibantools';
import { auth } from '@modules/auth';
import { useGetSoilCapitalCountryList } from '@shared/hooks/useGetSoilCapitalCountryList';

export const BILLING_INFOS = {
    NAME: 'name',
    ADDRESS: 'address',
    CITY: 'city',
    POSTAL_CODE: 'postal_code',
    COUNTRY: 'country_id',
    VAT: 'tax_id',
    SIRET: 'siret_id',
    IBAN: 'iban',
} as const;

export const useBillingInfoFormLogic = (
    refetch: () => void,
    onClose?: () => void,
    userCompany?: CompanyT,
    setDisableContinue?: (disable: boolean) => void,
    setIsSubmitting?: (isSubmitting: boolean) => void,
    nextAction?: () => void,
) => {
    const canEditBillingPage = auth.usePermission('canEditBillingPage');
    const isReadOnly = !canEditBillingPage;
    const canEditIban = auth.usePermission('canEditIban');
    const { t } = useTranslation();
    const { countries } = useGetSoilCapitalCountryList();
    const { currentSeasonId } = useCurrentSeasonId();
    const { farmState } = entities.farm.useState({
        farmSeasonId: currentSeasonId ? currentSeasonId : auth.useLatestFarmSeasonId() || 0,
    });
    const isFarmStateLoading = farmState.isLoading;
    const [userCompanyId, setUserCompanyId] = useState<number | null>(userCompany?.id || null);
    const { enqueueSnackbar } = useSnackbar();
    const farm = farmState.list[0];
    const isFranceSelected = useCallback(
        (selectedCountry: string): boolean => {
            const selectedCountrySlug = countries.find((country) => country.id.toString() === selectedCountry)?.name;

            return selectedCountrySlug === countrySlugs.FR;
        },
        [countries],
    );
    const isIrelandSelected = useCallback(
        (selectedCountry: string): boolean => {
            const selectedCountrySlug = countries.find((country) => country.id.toString() === selectedCountry)?.name;

            return selectedCountrySlug === countrySlugs.IE;
        },
        [countries],
    );
    const defaultValues = {
        [BILLING_INFOS.NAME]: userCompany?.name || '',
        [BILLING_INFOS.ADDRESS]: userCompany?.address || '',
        [BILLING_INFOS.CITY]: userCompany?.city || '',
        [BILLING_INFOS.POSTAL_CODE]: userCompany?.postal_code || '',
        [BILLING_INFOS.COUNTRY]: userCompany?.country?.id?.toString() || '0',
        [BILLING_INFOS.VAT]: userCompany?.tax_id || null,
        [BILLING_INFOS.SIRET]: userCompany?.siret_id || null,
        [BILLING_INFOS.IBAN]: userCompany?.iban || null,
    };
    const farmValues = {
        [BILLING_INFOS.NAME]: farm?.name || '',
        [BILLING_INFOS.ADDRESS]: farm?.address || '',
        [BILLING_INFOS.CITY]: farm?.city || '',
        [BILLING_INFOS.POSTAL_CODE]: farm?.postal_code || '',
        [BILLING_INFOS.COUNTRY]: farm?.country.id.toString() || '0',
        [BILLING_INFOS.SIRET]: null,
    };

    const schema = z
        .object({
            [BILLING_INFOS.NAME]: z.string().min(1, t('billing.subscription.billing-form.company-name.error')),
            [BILLING_INFOS.ADDRESS]: z.string().min(1, t('billing.subscription.billing-form.address.error')),
            [BILLING_INFOS.CITY]: z.string().min(1, t('billing.subscription.billing-form.city.error')),
            [BILLING_INFOS.POSTAL_CODE]: z.string().min(1, t('billing.subscription.billing-form.postal-code.error')),
            [BILLING_INFOS.COUNTRY]: z.string().min(1, t('billing.subscription.billing-form.country.error')),
            [BILLING_INFOS.VAT]: z.string().nullable(),
            [BILLING_INFOS.SIRET]: z.string().nullable(),
            [BILLING_INFOS.IBAN]: z.string().nullable(),
        })
        .refine(
            (data) => {
                return (
                    !isFromFrance || (data[BILLING_INFOS.SIRET] !== null && data[BILLING_INFOS.SIRET]?.length === 14)
                );
            },
            {
                message: t('billing.subscription.billing-form.siret.error'),
                path: [BILLING_INFOS.SIRET],
            },
        )
        .refine(
            (data) => {
                return canEditIban ? isValidIBAN(electronicFormatIBAN(data[BILLING_INFOS.IBAN] ?? '') as string) : true;
            },
            {
                message: t('billing.subscription.billing-form.IBAN.error'),
                path: [BILLING_INFOS.IBAN],
            },
        )
        .refine(
            (data) => {
                if (isFromIreland) {
                    return !data[BILLING_INFOS.VAT] || checkVATNumber(data[BILLING_INFOS.VAT] ?? '');
                }
                // For non-Irish, VAT must be provided and valid.
                return data[BILLING_INFOS.VAT] && checkVATNumber(data[BILLING_INFOS.VAT] ?? '');
            },
            {
                message: t('billing.subscription.billing-form.VAT.error'),
                path: [BILLING_INFOS.VAT],
            },
        );

    const methods = useForm({ schema, defaultValues, displaySnackbarOnSchemaError: false, mode: 'onBlur' });
    const [postUserCompany, { isLoading: isPostFormLoading }] = usePostUserCompanyMutation();
    const [putUserCompany, { isLoading: isPutFormLoading }] = usePutUserCompanyMutation();
    const [putIban, { isLoading: isPutIbanLoading }] = usePutIbanMutation();
    const {
        handleSubmit,
        formState: { isValid, errors },
        watch,
        reset,
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        let response;
        if (userCompanyId) {
            response = await putUserCompany({ farmId: farm.id, companyId: userCompanyId, company: data });
        } else response = await postUserCompany({ farmId: farm.id, company: data });
        if ('error' in response && response.error) {
            enqueueSnackbar(t('billing.subscription.billing-form.error'), {
                variant: 'error',
            });
        } else if ('data' in response && response.data) {
            setUserCompanyId(response.data.id);

            if (canEditIban) {
                const ibanResponse = await putIban({
                    farmId: farm.id,
                    companyId: response.data.id,
                    iban: { iban: data.iban },
                });
                if ('error' in ibanResponse && ibanResponse.error) {
                    enqueueSnackbar(t('billing.subscription.billing-form.error'), {
                        variant: 'error',
                    });
                }
            }
            if (nextAction) {
                nextAction();
            }
        }
    });

    const onConfirm = async () => {
        await onSubmit();
        if (isValid && onClose) {
            onClose();
            refetch();
        }
    };

    const handlePrefillClick = (isChecked: boolean) => {
        if (isChecked) {
            reset(farmValues);
        } else {
            reset(defaultValues);
        }
    };
    const selectedCountry: string = watch(BILLING_INFOS.COUNTRY);
    const [isFromFrance, setIsFromFrance] = useState(isFranceSelected(selectedCountry));
    const [isFromIreland, setIsFromIreland] = useState(isIrelandSelected(selectedCountry));

    useEffect(() => {
        setIsFromFrance(isFranceSelected(selectedCountry));
        setIsFromIreland(isIrelandSelected(selectedCountry));
    }, [selectedCountry, isFranceSelected, isIrelandSelected]);

    const isFormSubmitting = isPostFormLoading || isPutFormLoading || isPutIbanLoading;
    useEffect(() => {
        setDisableContinue?.(isValid);
    }, [setDisableContinue, isValid]);

    useEffect(() => {
        setIsSubmitting?.(isFormSubmitting);
    }, [setIsSubmitting, isFormSubmitting]);

    return {
        methods,
        countries,
        errors,
        onSubmit,
        onConfirm,
        handlePrefillClick,
        selectedCountry,
        isFromFrance,
        isValid,
        isFormSubmitting,
        isReadOnly,
        canEditIban,
        isFarmStateLoading,
    };
};
