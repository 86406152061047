import { useCroppingViewAccessCheck } from './hooks/useCroppingViewAccessCheck';
import { useTailwindCdn } from './hooks/useTailwindCdn';
import { LegendOperations } from './components/LegendOperations/LegendOperations';
import { CroppingViewTable } from './components/CroppingViewTable/CroppingViewTable';
import { CroppingLayout } from './components/CroppingLayout/CroppingLayout';
import CroppingViewData from './CroppingView.data';
import { CroppingFilter } from './components/CroppingFilter/CroppingFilter';
import { useCallback, useEffect, useState } from 'react';
import { LoaderFullScreen } from '@shared/components';
import { Button } from '@soil-capital/ui-kit/components';
import { makeStyles } from '@soil-capital/ui-kit/style';
import { Typography } from '@soil-capital/ui-kit/material-core';

export const CroppingView = () => {
    useTailwindCdn();

    const { classes } = makeStyles()(() => ({
        inheritButton: {
            display: 'inline-block',
            marginLeft: '6px',
            color: 'gray',
            ':hover': {
                backgroundColor: '#cacaca',
            },
        },
    }))();

    const [fadeIn, setFadeIn] = useState(true);
    useEffect(() => {
        setTimeout(() => setFadeIn(false), 500);
    }, []);

    useCroppingViewAccessCheck();
    const [selectedFieldId, setSelectedFieldId] = useState<number | null>(null);

    const { dndcCroppingFetcher, mscCroppingFetcher, clearUrlParamFieldIds, urlParamFieldIds } = CroppingViewData();
    const isFetching = dndcCroppingFetcher.fetching || mscCroppingFetcher.fetching;

    const mscCroppingData = mscCroppingFetcher.data?.find((mscData) => mscData.field_id === selectedFieldId);
    const dndcCroppingData = dndcCroppingFetcher.data?.find((dndcData) => dndcData.field_id === selectedFieldId);

    const handleGetCropping = useCallback(
        (fieldIds: number[]) => {
            dndcCroppingFetcher.fetch({ field_ids: fieldIds });
            mscCroppingFetcher.fetch({ field_ids: fieldIds });
        },
        [dndcCroppingFetcher, mscCroppingFetcher],
    );

    return (
        <LoaderFullScreen isLoading={fadeIn} displayChildrenWhileLoading displayLoader={false}>
            <CroppingLayout>
                <section className="p-8 text-gray-700" style={{ minWidth: '1500px' }}>
                    <h1 className="text-2xl font-bold">Cropping View</h1>
                    <CroppingFilter
                        preFilteredFieldIds={urlParamFieldIds}
                        onFieldIdChange={(id) => setSelectedFieldId(() => id)}
                        onSearchDone={handleGetCropping}
                        selectedFieldId={selectedFieldId}
                    />

                    <Typography variant="caption" className="w-full text-blue-700">
                        {urlParamFieldIds && (
                            <>
                                `field_ids` url param active ({urlParamFieldIds?.split(',').length} ids)
                                <Button
                                    size="small"
                                    onClick={clearUrlParamFieldIds}
                                    variant="contained"
                                    color="inherit"
                                    className={classes.inheritButton}
                                >
                                    Clear
                                </Button>
                            </>
                        )}
                    </Typography>

                    <div className="w-full mt-10">
                        <CroppingViewTable
                            yearStart={2020}
                            years={5}
                            fieldsCroppingData={mscCroppingData?.cropping_fields}
                            dndcCroppingData={dndcCroppingData}
                            monthsBeforeStart={3}
                            monthsAfterEnd={3}
                            className={isFetching ? 'opacity-50' : ''}
                        />
                        <LegendOperations />
                    </div>
                </section>
            </CroppingLayout>
        </LoaderFullScreen>
    );
};
