export const GridStatus = ({
    bgColor,
    startPosition,
    text,
}: {
    startPosition: number;
    bgColor: string;
    text: string;
}) => {
    return (
        <span
            className=" w-0 h-8"
            style={{
                gridColumn: `${startPosition}`,
            }}
        >
            <span className=" rounded-xl py-2 px-4 text-gray-100 opacity-80" style={{ backgroundColor: bgColor }}>
                {text}
            </span>
        </span>
    );
};
