import { createFieldsCroppingFetcher } from './api/fieldsCroppingFetcher';
import { createDndcCroppingFetcher } from './api/dndcCroppingFetcher';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default () => {
    const mscCroppingFetcher = createFieldsCroppingFetcher();
    const dndcCroppingFetcher = createDndcCroppingFetcher();

    const navigate = useNavigate();
    const location = useLocation();
    const [urlParamFieldIds, setUrlParamFieldIds] = useState(new URLSearchParams(location.search).get('field_ids'));

    const clearUrlParamFieldIds = () => {
        navigate({ search: '' });
        setUrlParamFieldIds('');
    };

    return { dndcCroppingFetcher, mscCroppingFetcher, urlParamFieldIds, clearUrlParamFieldIds };
};
