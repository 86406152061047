import { Header } from '@shared/components/Header/Header';
import { ReactComponent } from 'tss-react/tools/ReactComponent';

type CroppingLayoutPropsT = {
    children: React.ReactNode;
};
export const CroppingLayout: ReactComponent<CroppingLayoutPropsT> = (props) => {
    return (
        <section className="p-8 h-screen flex flex-col">
            <Header />
            <div className="flex-1 rounded-xl bg-gray-100">{props.children}</div>
        </section>
    );
};
