/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CroppingField } from '@modules/croppingView/api/fieldsCroppingFetcher';
import { useMemo } from 'react';
import { computeGridFromDate, getGridDayRatio } from '../utils/computeGridFromDate';
import { mscOperationTypes } from '@modules/croppingView/constants/msc-operation-types';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { Tooltip } from '@soil-capital/ui-kit/components';
import { IconInfo } from '@soil-capital/ui-kit/icons';
import { Link } from 'react-router-dom';
import { MediaInfoBlock } from '@soil-capital/ui-kit/MediaInfoBlock';
import { makeStyles } from '@soil-capital/ui-kit/style';
import { GridStatus } from '../../GridStatus/GridStatus';

const TooltipContent = ({ date, type }: { type: string; date?: string }) => {
    return (
        <div className="h-full w-full">
            <Typography variant="body">{type}</Typography>
            <Typography variant="body2">
                <span className="text-gray-500">date: </span>
                {date}
            </Typography>
        </div>
    );
};

const TooltipFieldContent = ({
    year,
    name,
    area,
    overlap,
    fieldCropId,
    fieldId,
    farmSeasonId,
}: {
    year: number;
    name: string;
    area: number;
    overlap: number;
    fieldCropId: number;
    fieldId: number;
    farmSeasonId: number;
}) => {
    return (
        <div className="h-full w-full flex gap-3">
            <MediaInfoBlock.Image
                src={`${process.env.REACT_APP_API_BASE_URL}/v3/farm-seasons/${farmSeasonId}/fields/${fieldId}/thumbnail`}
                style={{ width: '150px', height: '150px' }}
            />
            <div>
                <Typography variant="body">Year {year}</Typography>
                <Typography variant="body2">
                    <span className="text-gray-500">overlap: </span>
                    {(overlap * 100).toFixed(0)}%
                </Typography>
                <Typography variant="body2">
                    <span className="text-gray-500">name: </span>
                    {name}
                </Typography>
                <Typography variant="body2">
                    <span className="text-gray-500">area: </span>
                    {area} ha
                </Typography>
                <Typography variant="body2">
                    <span className="text-gray-500">field ID: </span>
                    {fieldId}
                </Typography>
                <Link
                    to={`/${farmSeasonId}/technical-itinerary/${fieldCropId}/global-characteristics`}
                    target="_blank"
                    className="text-blue-500 underline"
                >
                    Go to ITK
                </Link>
            </div>
        </div>
    );
};

export const MscFieldsCropping = ({
    gridTemplateColumns,
    monthsBeforeStart,
    monthsAfterEnd,
    yearStart,
    fieldsCroppingData,
}: {
    gridTemplateColumns: string;
    fieldsCroppingData?: CroppingField[] | null;
    yearStart: number;
    monthsBeforeStart: number;
    monthsAfterEnd: number;
}) => {
    const { classes } = makeStyles()(() => ({}))();

    const operationsByCropping = useMemo(() => {
        const croppingIdOperationMap: Record<
            number,
            { id: number; label: string; date: number; color: string; labelDate?: string }[]
        > = {};

        fieldsCroppingData
            ?.map((fieldCropping) => fieldCropping.operations)
            .flat()
            .filter((op) => op.operation_date !== null)
            .forEach((op) => {
                croppingIdOperationMap[op.field_crop_id] = croppingIdOperationMap[op.field_crop_id] || [];
                croppingIdOperationMap[op.field_crop_id].push({
                    id: op.operation_id,
                    label: op.operation_name,
                    labelDate: op.operation_date,
                    date: computeGridFromDate(op.operation_date, yearStart, monthsBeforeStart) as number,
                    color: mscOperationTypes[op.operation_name]?.color,
                });
            }, []);

        return croppingIdOperationMap;
    }, [fieldsCroppingData, yearStart, monthsBeforeStart]);

    const getCropSeedingOperation = (
        operations: { id: number; label: string; date: number; color: string; labelDate?: string }[],
    ) => {
        const firstSeedingOperation = operations
            ?.sort((a: any, b: any) => a.date - b.date)
            .filter((op) => op.label === 'Seeding');
        return firstSeedingOperation?.[0];
    };

    const getCropHarvestOperation = (
        operations: { id: number; label: string; date: number; color: string; labelDate?: string }[],
    ) => {
        const firstSeedingOperation = operations
            ?.sort((a: any, b: any) => a.date - b.date)
            .filter((op) => op.label === 'Harvest');
        return firstSeedingOperation?.[0];
    };

    const mscFieldCroppingComputed = useMemo(() => {
        const uniqueCroppingYear = new Set();

        const crops = fieldsCroppingData
            ?.sort((a, b) => (a.year === b.year ? b.overlap - a.overlap : b.year - a.year))
            ?.map((fieldCropping) => {
                let isMainField = false;
                if (uniqueCroppingYear.has(fieldCropping.year) === false) {
                    isMainField = true;
                    uniqueCroppingYear.add(fieldCropping.year);
                }
                return fieldCropping.crops.map((crop) => {
                    return {
                        isMainField,
                        isPermanent: crop.is_permanent,
                        isPerennial: crop.is_perennial,
                        start: getCropSeedingOperation(operationsByCropping[crop.field_crop_id])?.date,
                        end: getCropHarvestOperation(operationsByCropping[crop.field_crop_id])?.date ?? '-6',
                        color: '#2ecaac',
                        label: crop?.crop_name,
                        labelStartDate: getCropSeedingOperation(operationsByCropping[crop.field_crop_id])?.labelDate,
                        labelEndDate: getCropHarvestOperation(operationsByCropping[crop.field_crop_id])?.labelDate,
                        fieldName: fieldCropping.name,
                        fieldArea: fieldCropping.area,
                        fieldId: fieldCropping.id,
                        farmSeasonId: fieldCropping.farm_season_id,
                        id: crop?.field_crop_id,
                        year: fieldCropping.year,
                        overlap: fieldCropping.overlap,
                    };
                });
            })
            .flat();

        return crops;
    }, [fieldsCroppingData, operationsByCropping]);

    return (
        <>
            {mscFieldCroppingComputed?.map((fieldCropping, i) => (
                <div
                    key={fieldCropping.fieldId + i + Math.random()}
                    className={`
                        grid
                        border-t-[1px]
                        border-gray-300
                        border-solid
                        min-h-12
                        bg-gray-200
                        py-2`}
                    style={{
                        gridTemplateColumns,
                        ...(fieldCropping.isMainField
                            ? { borderTop: '1px solid rgba(0, 0, 0, 0.4)' }
                            : { opacity: '0.5' }),
                    }}
                >
                    {/* YEAR info */}
                    <span className="absolute">
                        <Tooltip
                            elements={
                                <TooltipFieldContent
                                    year={fieldCropping.year}
                                    overlap={fieldCropping.overlap}
                                    area={fieldCropping.fieldArea}
                                    name={fieldCropping.fieldName}
                                    fieldId={fieldCropping.fieldId}
                                    fieldCropId={fieldCropping.id}
                                    farmSeasonId={fieldCropping.farmSeasonId}
                                />
                            }
                            display="inline"
                            scheme="light"
                            placement="right-end"
                        >
                            <div className="m-2 mt-0">
                                <IconInfo color="primary" fontSize={'small'} className="mr-1" />
                                {fieldCropping.isMainField && <>Y {fieldCropping.year}</>}
                                {fieldCropping.overlap < 1 && (
                                    <Typography variant="caption" className="text-gray-400" component="div">
                                        {Math.floor(fieldCropping.overlap * 100)}%
                                    </Typography>
                                )}
                            </div>
                        </Tooltip>
                    </span>

                    {/* CROP */}
                    {fieldCropping.isPermanent ? (
                        <GridStatus
                            bgColor="#942f2f"
                            startPosition={getGridDayRatio(30 * (monthsBeforeStart + 2))}
                            text="PERMANENT"
                        />
                    ) : (
                        <span
                            className="text-nowrap rounded-md"
                            style={{
                                gridColumn: `${fieldCropping.start}/${fieldCropping.end}`,
                                backgroundColor: fieldCropping.color,
                            }}
                        >
                            <Tooltip
                                className="w-full h-full"
                                scheme="light"
                                display="block"
                                elements={
                                    <>
                                        <Typography variant="body">{fieldCropping.label}</Typography>
                                        <Typography variant="body2">
                                            <span className="text-gray-500">dates: </span>
                                            {fieldCropping.labelStartDate + ' -> ' + fieldCropping.labelEndDate}
                                        </Typography>
                                        <Typography variant="body2">
                                            <span className="text-gray-500">perennial: </span>
                                            {fieldCropping.isPerennial === true ? 'true' : 'false'}
                                        </Typography>
                                    </>
                                }
                                disableInteractive
                            >
                                <strong className="pl-2">{fieldCropping?.label}</strong>
                            </Tooltip>
                        </span>
                    )}

                    {/* OPERATIONS */}
                    {operationsByCropping[fieldCropping.id]?.map((operation, i) => (
                        <span
                            key={operation.id}
                            className="rounded-md h-3 mt-px relative"
                            style={{
                                gridColumn: `${operation.date - 1}/${operation.date + 1}`,
                                backgroundColor: operation.color,
                            }}
                        >
                            <Tooltip
                                elements={<TooltipContent date={operation.labelDate} type={operation.label} />}
                                display="inline"
                                scheme="light"
                                disableInteractive
                            >
                                <div className="w-full h-4" />
                            </Tooltip>
                        </span>
                    ))}
                </div>
            ))}
        </>
    );
};
