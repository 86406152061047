import { mscOperationTypes } from '@modules/croppingView/constants/msc-operation-types';
import { Tooltip } from '@soil-capital/ui-kit/components';

export const LegendOperations = () => {
    return (
        <div className="mt-4">
            <h2>Soilcapital operations:</h2>
            <ul className="text-gray-500">
                <li className="flex">
                    <Tooltip text="Seeding" disableInteractive scheme="light">
                        <div
                            className="w-2 h-4 m-1 rounded-sm text-normal"
                            style={{ backgroundColor: mscOperationTypes.Seeding.color }}
                        />
                    </Tooltip>
                    - Semis
                </li>
                <li className="flex">
                    <Tooltip text="Cover crop seeding" disableInteractive scheme="light">
                        <div
                            className="w-2 h-4 m-1 rounded-sm"
                            style={{ backgroundColor: mscOperationTypes['Cover crop seeding'].color }}
                        />
                    </Tooltip>
                    <Tooltip text="Cover crop destruction" disableInteractive scheme="light">
                        <div
                            className="w-2 h-4 m-1 rounded-sm"
                            style={{ backgroundColor: mscOperationTypes['Cover crop destruction'].color }}
                        />
                    </Tooltip>
                    - Culture de couverture
                </li>
                <li className="flex">
                    <Tooltip text="Shallow work machine (< 5 cm)" disableInteractive scheme="light">
                        <div
                            className="w-2 h-4 m-1 rounded-sm"
                            style={{ backgroundColor: mscOperationTypes['Shallow work machine (< 5 cm)'].color }}
                        />
                    </Tooltip>
                    <Tooltip text="Weeding machine" disableInteractive scheme="light">
                        <div
                            className="w-2 h-4 m-1 rounded-sm"
                            style={{ backgroundColor: mscOperationTypes['Weeding machine'].color }}
                        />
                    </Tooltip>
                    <Tooltip text="Roller" disableInteractive scheme="light">
                        <div
                            className="w-2 h-4 m-1 rounded-sm"
                            style={{ backgroundColor: mscOperationTypes.Roller.color }}
                        />
                    </Tooltip>
                    <Tooltip text="Deep work machine (> 5 cm)" disableInteractive scheme="light">
                        <div
                            className="w-2 h-4 m-1 rounded-sm"
                            style={{ backgroundColor: mscOperationTypes['Deep work machine (> 5 cm)'].color }}
                        />
                    </Tooltip>
                    <Tooltip text="Plough" disableInteractive scheme="light">
                        <div
                            className="w-2 h-4 m-1 rounded-sm"
                            style={{ backgroundColor: mscOperationTypes.Plough.color }}
                        />
                    </Tooltip>
                    - Travail du sol
                </li>
                <li className="flex">
                    <Tooltip text="Organic fertilisation" disableInteractive scheme="light">
                        <div
                            className="w-2 h-4 m-1 rounded-sm"
                            style={{ backgroundColor: mscOperationTypes['Organic fertilisation'].color }}
                        />
                    </Tooltip>
                    <Tooltip text="Mineral fertilisation" disableInteractive scheme="light">
                        <div
                            className="w-2 h-4 m-1 rounded-sm"
                            style={{ backgroundColor: mscOperationTypes['Mineral fertilisation'].color }}
                        />
                    </Tooltip>
                    - Fertilisation
                </li>
                <li className="flex">
                    <Tooltip text="Harvest" disableInteractive scheme="light">
                        <div
                            className="w-2 h-4 m-1 rounded-sm"
                            style={{ backgroundColor: mscOperationTypes.Harvest.color }}
                        />
                    </Tooltip>
                    <Tooltip text="Maintenance shredding" disableInteractive scheme="light">
                        <div
                            className="w-2 h-4 m-1 rounded-sm"
                            style={{ backgroundColor: mscOperationTypes['Maintenance shredding'].color }}
                        />
                    </Tooltip>
                    - Récolte
                </li>
                {/* Orange (Pulvérisation) */}
            </ul>
            <div className="h-px bg-gray-400 my-3" />
            <ul className="text-gray-400 border-l-4 border-solid border-blue-400 pl-4">
                <li>- The FARMER input search on `email`, `first_name` or `last_name`</li>
                <li>- The FIELD input search on `name` or `id`</li>
                <li>- A pre-filter can be added through the url query param `?field_ids=1,2,3...` </li>
                <li>- The regrow data are got from the `farm_season_field_carbon_values_test` table</li>
                <li>
                    - The irrigations are not displayed. Their date are defaulted as `
                    {'(harvest - 1)-06-01 -> (harvest - 1)-08-31'}`. They fall in the phase that include the start date.
                    If the end date is out of phase it is moved to the end of the fase it is in.
                </li>
            </ul>
        </div>
    );
};
